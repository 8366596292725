<template>
  <manage title="国有资产" :load-parm="loadParm"></manage>
</template>

<script>
import Manage from './manage'

export default {
  components: {
    Manage
  },
  data () {
    return {
      loadParm: {
        beloneType: '国有资产'
      }
    }
  }
}
</script>
